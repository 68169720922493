import {
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { assets } from '../../../core/const/assetsConst';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  MatDialog,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import {
  MatCheckboxChange,
  MatCheckboxModule
} from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { BaseClass } from '../../../shared/class/baseClass';
import { AuthService } from '../../../services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { emailErrors } from '../../../core/const/commonErrors.const';
import { LoginPayload, LoginResponse } from '../../../models/auth.model';

@Component({
  selector: 'xplora-luxe-login',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent extends BaseClass implements OnInit, OnDestroy {
  private destroy: Subject<boolean> = new Subject();
  protected assetConst = assets;
  protected hide = true;
  protected loginFormGroup: FormGroup;
  protected submitted = false;
  protected emailErrors = emailErrors;
  protected isRemembered = false;
  constructor(
    public injector: Injector,
    private _authService: AuthService,
    private dialogRef: MatDialogRef<LoginComponent>,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    super(injector);
    this.loginFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
      // deviceType: ['WEB'],
      // deviceToken: localStorage.getItem('fcmToken')
    });
  }

  ngOnInit(): void {
    const rememberMeData = localStorage.getItem(environment.rememberMeKey);
    if (rememberMeData) {
      this.isRemembered = true;

      const body = JSON.parse(rememberMeData);

      this.loginFormGroup.patchValue({
        email: body.email,
        password: body.password
      });
    }
  }

  togglePasswordVisibility(): void {
    this.hide = !this.hide;
  }

  toggleRememberMe(event: MatCheckboxChange): void {
    this.isRemembered = event['checked'];
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.loginFormGroup.valid) {
      this.spinnerService.addToLoader('login');
      this._authService
        .loginUser(this.loginFormGroup.value)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: (res: LoginResponse) => {
            this.handleSuccessResponse(res);
          },
          error: () => {
            this.spinnerService.removeFromLoader('login');
          }
        });
    }
  }

  handleSuccessResponse(res: LoginResponse): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(
        environment.storageKey,
        res.accessToken ? res.accessToken : ''
      );
      this._authService.profileDetails.next(res);
      this.spinnerService.removeFromLoader('login');
      if (this.isRemembered) {
        const data: LoginPayload = {
          email: this.loginFormGroup['controls']['email']['value'],
          password: this.loginFormGroup['controls']['password']['value'],
          deviceType: this.loginFormGroup['controls']['deviceType']['value'],
          deviceToken: this.loginFormGroup['controls']['deviceToken']['value']
        };
        localStorage.setItem(
          environment.rememberMeKey,
          JSON.stringify(data)
        );
      } else {
        localStorage.removeItem(environment.rememberMeKey);
      }
      this.toasterService.successToastr(
        this.translateService.instant('LoginSuccessful')
      );
      this._authService.isLoggedIn.next(true);
      this.router.navigate(['/bookings']);
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.destroy.complete();
    this.destroy.unsubscribe();
  }

  ForgotModal(): void {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      panelClass: ['modal', 'auth_modal']
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
}
