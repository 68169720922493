<section class="auth_sc">
  <div class="auth_flx gap_m">
    <div class="auth_left">
      <figure class="logo">
        <img src="{{ assetsConst.Logo }}" alt="logo" />
      </figure>
      <h1>{{ 'bannerText' | translate }}</h1>
    </div>
    <div class="auth_rht">
      <div class="modal_head">
        <button class="btn btn_secondary" [mat-dialog-close]="true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
        <form class="auth_form" [formGroup]="forgotPasswordFormGroup"
        (ngSubmit)="onSubmit()">
          <h2 class="auth_subTitle">
            {{ 'enterEmail' | translate }}
          </h2>
          <div class="form_group w_100">
            <mat-label>{{ 'EmailAddress' | translate }}</mat-label>
            <mat-form-field appearance="fill">
              <input
                matInput
                type="email"
                placeholder="{{ 'EnterHere' | translate }}"
                formControlName="email"
                class="form_control" />
                @if (submitted) {
                  @for (err of emailErrors; track $index) {
                    @if (forgotPasswordFormGroup.controls['email'].hasError(err.key)) {
                      <mat-error>{{ err.message | translate }} </mat-error>
                    }
                  }
                }
            </mat-form-field>
            <div></div>
          </div>
          <div class="form_btn">
            <button class="btn btn_primary w_100" type="submit">
              {{ 'Submit' | translate }}
            </button>
          </div>
        </form>
    </div>
  </div>
</section>
