import { keyMessage } from '../../models/common.model';

export const nameErrors: Array<keyMessage> = [
  {
    key: 'required',
    message: 'nameRequired'
  },
  {
    key: 'minlength',
    message: 'nameMinLength'
  },
  {
    key: 'maxlength',
    message: 'nameMaxLength'
  }
];

export const emailErrors: Array<keyMessage> = [
  {
    key: 'required',
    message: 'emailRequired'
  },
  {
    key: 'email',
    message: 'enterEmailValid'
  }
];

export const passwordFormErrors: Array<keyMessage> = [
  {
    key: 'required',
    message: 'passwordRequired'
  },
  {
    key: 'minLength',
    message: 'passwordMinLength'
  },
  {
    key: 'maxLength',
    message: 'passwordMaxLength'
  },
  {
    key: 'uppercase',
    message: 'passwordOneUpperCase'
  },
  {
    key: 'lowercase',
    message: 'passwordOneLowerCase'
  },
  {
    key: 'number',
    message: 'passwordOneNumber'
  },
  {
    key: 'specialCharacter',
    message: 'passwordOneSpecial'
  },
  {
    key: 'repeatedCharacters',
    message: 'passwordIdentical'
  },
  {
    key: 'commonWords',
    message: 'passwordCommon'
  }
];