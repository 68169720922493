import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function nameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return { required: true };
    }
    if (value.length < 3) {
      return { minlength: { requiredLength: 3,
        actualLength: value.length } };
    }
    if (value.length > 80) {
      return { maxlength: { requiredLength: 80,
        actualLength: value.length } };
    }
    return null;
  };
}

export function MustMatch(
  controlName: string,
  matchingControlName: string
): ValidationErrors | null {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

// eslint-disable-next-line no-useless-escape
const passwordRegex: RegExp = new RegExp(/[\!\\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:;<\=>\?@\[\\\]\^_\`\{\|\}~]/);
export function passwordValidatorHelper(
  control: AbstractControl,
  formData: {
      firstName: string | null;
      userEmail: string;
    }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const password = control.value;
  if (!password || password.length < 8) {
    return { minLength: true };
  }

  if (password.length > 25) {
    return { maxLength: true };
  }

  if (!/[A-Z]/.test(password)) {
    return { uppercase: true };
  }

  if (!/[a-z]/.test(password)) {
    return { lowercase: true };
  }

  if (!/\d/.test(password)) {
    return { number: true };
  }

  if (!passwordRegex.test(password)) {
    return { specialCharacter: true };
  }

  if (/(\w)\1{2}/.test(password)) {
    return { repeatedCharacters: true };
  }

  const commonlyUsedPwd = [
    'P@ssword123',
    'Password@123',
    'pASSWORD@123',
    'p@ssword123'
  ];

  if (
    password.includes(formData.firstName) ||
        password.includes(formData.userEmail.split('@')[0]) ||
        password.includes(formData.userEmail.split('@')[1].split('.')[0]) ||
        password.includes(formData.userEmail.split('@')[1].split('.')[1]) ||
        commonlyUsedPwd.includes(password)
  ) {
    return { commonWords: true };
  }

  return null;
}

export function atLeastOneBrandIdValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return Array.isArray(value) && value.length === 0 ? { atLeastOneBrandId: true } : null;
  };
}

export function atLeastOneOfferIdValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return Array.isArray(value) && value.length === 0 ? { atLeastOneOfferId: true } : null;
  };
}

export function atLeastOneUserIdValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return Array.isArray(value) && value.length === 0 ? { atLeastOneUserId: true } : null;
  };
}
