<div class="auth_form">
  <h2 class="auth_subTitle">
    {{ 'enterOTP' | translate }}
  </h2>
  <div class="form_group w_100">
    <ng-otp-input [config]="{ length: 4, allowNumbersOnly: true, isPasswordInput: false, disableAutoFocus: false }"
    #ngOtpInput></ng-otp-input>
  </div>
  <button
    class="btn btn_primary w_100"
    (click)="verifyOtp()">
    {{ 'Verify' | translate }}
  </button>
</div>
<div
  class="auth_bottom"
  (click)="otpTimer === 0 ? sendOtpAgain() : ''"
  (keydown.enter)="sendOtpAgain()"
  tabindex="0">

  <p>
    <strong
      >{{ 'ResendOtp' | translate }}
      @if (otpTimer > 0) {
        <span>({{ otpTimer }})</span>
      }
    </strong>
  </p>
</div>
