<section class="auth_sc">
  <div class="auth_flx gap_m">
    <div class="auth_left">
      <figure class="logo">
        <img src="{{ assetsConst.Logo }}" alt="logo" />
      </figure>
      <h1>{{ 'bannerText' | translate }}</h1>
    </div>
    <div class="auth_rht">
      <div class="modal_head">
        <button class="btn btn_secondary" [mat-dialog-close]="true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <xplora-luxe-otp
      (sendOtpAgainHandler)="fetchOtpSendOutput($event)"
      (verifyOtpOutput)="fetchOtpSubmitOutput($event)"
      ></xplora-luxe-otp>
    </div>
  </div>
</section>
