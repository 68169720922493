<div class="form_group search">
    <mat-form-field  appearance="fill">
      <input #search  matInput [placeholder]="placeholder | translate" class="form_control" />
    </mat-form-field>
    <button mat-button matPrefix mat-icon-button class="btnn">
      <figure *ngIf="!showCrossIcon">
        <img src="{{ assetsConst.searchIcon }}" alt="searchIcon" />
      </figure>
      <mat-icon *ngIf="showCrossIcon" (click)="clearInputField()">close</mat-icon>
    </button>
  </div>
  