<section class="auth_sc">
  <div class="auth_flx gap_m">
    <div class="auth_left">
      <figure class="logo">
        <img [src]="assetsConst.Logo" alt="logo" />
      </figure>
      <h1>{{ 'bannerText' | translate }}</h1>
    </div>
    <div class="auth_rht">
      <div class="modal_head">
        <button class="btn btn_secondary" [mat-dialog-close]="true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="auth_form_wrp">
        <h2>
          {{ 'Hey' | translate }},
          <span class="d_block"> {{ 'WelcomeBack' | translate }}!</span>
        </h2>
        <p>{{ 'Weareveryhappy' | translate }}!</p>
        <form [formGroup]="resetPasswordFormGroup" (ngSubmit)="onSubmit()">
          <div class="form_feilds gap_m">
            <div class="form_group w_100">
              <mat-label>{{ 'NewPassword' | translate }}</mat-label>
              <mat-form-field appearance="fill">
                <input
                  matInput
                  [placeholder]="'NewPassword'|translate"
                  class="form_control"
                  [type]="hiddenNewPassowrdVisibility ? 'password' : 'text'" 
                  formControlName="newPassword"
                  />
              </mat-form-field>
              <span
                class="input_icon input_icon_right"
                mat-icon-button
                matSuffix
                tabindex="0"
                (keydown.enter)="togglePasswordVisibility('hiddenNewPassowrdVisibility')"
                (keydown.space)="togglePasswordVisibility('hiddenNewPassowrdVisibility')"
                (click)="togglePasswordVisibility('hiddenNewPassowrdVisibility')"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hiddenNewPassowrdVisibility">
                <mat-icon>{{
                  hiddenNewPassowrdVisibility ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </span>
            </div>
            <div class="form_group w_100">
              <mat-label>{{ 'ConfirmPassword' | translate }}</mat-label>
              <mat-form-field appearance="fill">
                <input
                  matInput
                  [placeholder]="'ConfirmPassword'|translate"
                  class="form_control"
                  formControlName="confirmPassword"
                  [type]="hiddenConfirmPasswordVisibility ? 'password' : 'text'" />
              </mat-form-field>
              <span
                class="input_icon input_icon_right"
                mat-icon-button
                matSuffix
                tabindex="0"
                (keydown.enter)="togglePasswordVisibility('hiddenConfirmPasswordVisibility')"
                (keydown.space)="togglePasswordVisibility('hiddenConfirmPasswordVisibility')"
                (click)="togglePasswordVisibility('hiddenConfirmPasswordVisibility')"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hiddenConfirmPasswordVisibility">
                <mat-icon>{{
                  hiddenConfirmPasswordVisibility ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </span>
            </div>
          </div>
          <div class="form_btn">
            <button class="btn btn_primary w_100" type="submit">
              {{ 'Submit' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
