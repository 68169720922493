

export const assets = {
  Logo: 'assets/images/logo.png',
  searchIcon: 'assets/images/search_icon.svg',
  filterIcon: 'assets/images/filter_icon.svg',
  appLogo: 'assets/images/app_logo.svg',
  appLogo2: 'assets/images/app_logo2.svg',
  appLogo3: 'assets/images/app_logo3.svg',
  appLogo4: 'assets/images/app_logo4.svg',
  rightArrow: 'assets/images/right_arrow.svg',
  mobileImage: 'assets/images/mobile_img.png',
  webImage: 'assets/images/web_img.png',
  appleIcon: 'assets/images/apple_icon.svg',
  webIcon: 'assets/images/web_icon.svg',
  androidIcon: 'assets/images/android_icon.svg',
  reactIcon: 'assets/images/react_icon.svg',
  userIcon: 'assets/images/user_icon.svg',
  emailIcon: 'assets/images/email_icon.svg',
  phoneIcon: 'assets/images/phone_icon.svg',
  userProfile: 'assets/images/user_profile.png',
  userDummy: 'assets/images/profile.png',
  cameraIcon: 'assets/images/camera_icon.svg',
  noDataFound: 'assets/images/Nodat-found.gif'


};
