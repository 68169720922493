<section class="auth_sc">
  <div class="auth_flx gap_m">
    <div class="auth_left">
      <figure class="logo">
        <img src="{{ assetConst.Logo }}" alt="logo" />
      </figure>
      <h1>{{ 'bannerText' | translate }}</h1>
    </div>
    <div class="auth_rht">
      <div class="modal_head">
        <button class="btn btn_secondary" [mat-dialog-close]="true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="auth_form_wrp">
        <h2>
          {{ 'Hey' | translate }},
          <span class="d_block"> {{ 'WelcomeBack' | translate }}!</span>
        </h2>
        <p>{{ 'Weareveryhappy' | translate }}!</p>
        <form [formGroup]="loginFormGroup" (ngSubmit)="onSubmit()">
          <div class="form_feilds gap_m">
            <div class="form_group">
              <mat-label>{{ 'Email' | translate }}</mat-label>
              <mat-form-field appearance="fill">
                <input
                  matInput
                  [placeholder]="'Email' | translate"
                  formControlName="email"
                  class="form_control" />
                @if (submitted) {
                  @for (err of emailErrors; track $index) {
                    @if (loginFormGroup.controls['email'].hasError(err.key)) {
                      <mat-error>{{ err.message | translate }} </mat-error>
                    }
                  }
                }
              </mat-form-field>
            </div>
            <div class="form_group">
              <mat-label>{{ 'Password' | translate }}</mat-label>
              <mat-form-field appearance="fill">
                <input
                  matInput
                  [placeholder]="'Password' | translate"
                  class="form_control"
                  formControlName="password"
                  [type]="hide ? 'password' : 'text'" />

                @if (submitted) {
                  @if (loginFormGroup.get('password')?.hasError('required')) {
                    <mat-error>
                      {{ 'Password' | translate }}
                      {{ 'isRequired' | translate }}</mat-error
                    >
                  }
                }
              </mat-form-field>
              <span
                class="input_icon input_icon_right"
                mat-icon-button
                matSuffix
                tabindex="0"
                (keydown.enter)="togglePasswordVisibility()"
                (keydown.space)="togglePasswordVisibility()"
                (click)="togglePasswordVisibility()"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{
                  hide ? 'visibility_off' : 'visibility'
                }}</mat-icon>
              </span>
            </div>
            <div class="form_group terms w_100">
              <mat-checkbox [checked]="isRemembered"
              (change)="toggleRememberMe($event)">{{ 'RemberMe' | translate }}</mat-checkbox>
              <p
                (click)="ForgotModal()"
                tabindex="0"
                (keydown.enter)="togglePasswordVisibility()"
                [mat-dialog-close]="true">
                {{ 'Forgot Password?' | translate }}
              </p>
            </div>
          </div>
          <div class="form_btn">
            <button class="btn btn_primary w_100" type="submit">
              {{ 'Login' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
