/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, AfterViewInit, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { fromEvent, map, debounceTime, distinctUntilChanged } from 'rxjs';
import { assets } from '../../../core/const/assetsConst';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'xplora-luxe-search',
  standalone: true,
  imports: [MatIconModule, MatInputModule, CommonModule, TranslateModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements AfterViewInit {
  @ViewChild('search')
    searchRef!: ElementRef;
  assetsConst = assets;

  showCrossIcon = false;
  search = '';
@Input() placeholder:any = 'Search';
@Output() searchOutput: EventEmitter<any> = new EventEmitter();
constructor() {

}

ngAfterViewInit(): void {
  fromEvent(this.searchRef.nativeElement, 'keyup')
    .pipe(
      map((i: any) => i.target.value),
      debounceTime(500),
      distinctUntilChanged()
    )
    .subscribe((res: any) => {
      if (res) {
        this.showCrossIcon = true;
        this.search = res;
        this.searchOutput.emit(res);
      } else {
        this.showCrossIcon = false;
        this.search = '';
        this.searchOutput.emit(res);
      }
    });
}

clearInputField(): void {
  this.searchRef.nativeElement.value = '';
  this.search = '';
  this.showCrossIcon = false;
  this.searchOutput.emit(null);
}
}
