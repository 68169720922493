import { Component, EventEmitter, Inject, Injector, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { BaseClass } from '../../class/baseClass';
import { assets } from '../../../core/const/assetsConst';

@Component({
  selector: 'xplora-luxe-otp',
  standalone: true,
  imports: [
    MatInputModule,
    TranslateModule,
    MatDialogModule,
    NgOtpInputModule
  ],
  templateUrl: './otp.component.html',
  styleUrl: './otp.component.scss'
})
export class OtpComponent extends BaseClass {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('ngOtpInput') ngOtpInput: any;

  protected showTimerString = true;
  protected otpTimer = 60;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intervalHandle: any;
  protected sendAgain = false;
  private otp!: string;
  protected assetsConst = assets;

  @Output() sendOtpAgainHandler: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() verifyOtpOutput: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    injector: Injector,
    private dialogRef: MatDialogRef<OtpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string = ''
  ) {
    super(injector);
    this.startTimer();
  }

  startTimer(): void {
    this.intervalHandle = setInterval(() => {
      if (this.otpTimer > 0) {
        if (this.sendAgain) this.sendAgain = false;
        this.otpTimer--;
      } else {
        this.sendAgain = true;
        this.showTimerString = false;
        clearInterval(this.intervalHandle);
      }
    }, 1000);
  }

  sendOtpAgain(): void {
    this.showTimerString = true;
    this.otpTimer = 60;
    this.startTimer();
    this.sendOtpAgainHandler.emit(true);
  }

  verifyOtp(): void {
    this.otp = this.ngOtpInput.currentVal;
    if (this.otp === undefined || this.otp.length !== 4) {
      this.toasterService.errToastr(
        this.translateService.instant('VaidateLength')
      );
      return;
    }
    if (this.otp.length === 4) {
      if (this.data === 'verify') {
        this.dialogRef.close(this.otp);
      } else {
        this.verifyOtpOutput.emit(this.otp);
      }
    } else {
      this.toasterService.errToastr(this.translateService.instant('AddOTP'));
    }
  }

  ResetModal(): void {
    // todo
  }
}
