export const encryptionKeys = {
  IV: '7g5y6fdlctdegihj',
  key: '5pa7nohechag5j2'
};


export const UserType = {
  Admin: '1',
  User: '2'
};


export const statusTabs = {
  Pending: 0,
  Accepted: 1,
  Rejected: 2
};

export const statusConst = [
  {
    key: 'Accepted',
    value: 1
  },
  {
    key: 'Rejected',
    value: 2
  }
];

export const StatusConst = {
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: 2
};

export const bookingStatusTabs = {
  Pending: 0,
  Reedemed: 1
};
