import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../modals/auth/login/login.component';
import { HttpClient } from '@angular/common/http';
import { ForgotPayload, LoginPayload, LoginResponse, logoutResponse, ResetPasswordPayload, ResetPasswordResponse, SendCredentialsPayload, VerifyOtpPayload, VerifyOtpResponse } from '../models/auth.model';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { CommonResponse } from '../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public emailSubject: BehaviorSubject<ForgotPayload | null> =
    new BehaviorSubject<ForgotPayload | null>(null);
  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  resetTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  profileDetails: BehaviorSubject<LoginResponse | null> =
    new BehaviorSubject<LoginResponse | null>(null);
  constructor(
    public dialog: MatDialog,
    private http: HttpClient,
    private _translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  private stepperAction = new Subject<void>();

  triggerNextStep(): void {
    this.stepperAction.next();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getStepperAction(): any {
    return this.stepperAction.asObservable();
  }

  openLoginModal(): void {
    this.dialog.open(LoginComponent, {
      panelClass: ['modal', 'auth_modal']
    });
  }

  isAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (
        localStorage.getItem(environment.storageKey) &&
        localStorage.getItem(environment.storageKey) !== null
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  loginUser(loginDetails: LoginPayload): Observable<LoginResponse> {
    return this.http
      .post<{
        data: LoginResponse;
      }>(`${environment.base_url}restaurant/login`, loginDetails)
      .pipe(map(res => res.data));
  }

  forgotPassword(email: ForgotPayload): Observable<CommonResponse> {
    return this.http.post<CommonResponse>(
      `${environment.base_url}restaurant/forgotPassword`,
      email
    );
  }

  verifyOtp(otp: VerifyOtpPayload): Observable<VerifyOtpResponse> {
    return this.http
      .post<{
        data: VerifyOtpResponse;
      }>(`${environment.base_url}restaurant/verifyForgotPass`, otp)
      .pipe(map(res => res.data));
  }


  getProfile(): Observable<LoginResponse> {
    return this.http
      .get<{ data: LoginResponse }>(`${environment.base_url}restaurant/getProfile`)
      .pipe(map(res => res.data));
  }

  resetPassword(data: ResetPasswordPayload): Observable<ResetPasswordResponse> {
    return this.http
      .post<ResetPasswordResponse>(`${environment.base_url}restaurant/resetPassword`, data)
      .pipe(map(res => res));
  }

  logout(): Observable<logoutResponse> {
    return this.http.get<logoutResponse>(`${environment.base_url}restaurant/logout`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logoutAlert(): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise((resolve: any) => {
      Swal.fire({
        title: this._translateService.instant('AreYouSure'),
        text: this._translateService.instant('Youwillbeloggedout'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this._translateService.instant('Cancel'),
        confirmButtonColor: '#ffaf18',
        cancelButtonColor: '#000',
        confirmButtonText: this._translateService.instant('Logout')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).then((result: any) => {
        return resolve(result);
      });
    });
  }

  sendCredentials(payload: SendCredentialsPayload): Observable<SendCredentialsPayload> {
    return this.http
      .post<{
        data: SendCredentialsPayload;
      }>(`${environment.base_url}restaurant/acceptMemberRequest`, payload)
      .pipe(map(res => res.data));
  }

  updateProfile(data: LoginResponse): Observable<LoginResponse> {
    return this.http
      .put<{
        data: LoginResponse;
      }>(`${environment.base_url}restaurant/updateProfile`, data)
      .pipe(map(res => res.data));
  }

}
