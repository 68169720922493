import { Component, Injector, OnDestroy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { OtpComponent } from '../../../shared/components/otp/otp.component';
import { BaseClass } from '../../../shared/class/baseClass';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { ForgotPayload, VerifyOtpPayload, VerifyOtpResponse } from '../../../models/auth.model';
import { CommonResponse } from '../../../models/common.model';
import { responseStatus } from '../../../core/const/responseStatus.const';
import { assets } from '../../../core/const/assetsConst';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'xplora-luxe-auth-otp',
  standalone: true,
  imports: [
    TranslateModule, 
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    OtpComponent
  ],
  templateUrl: './auth-otp.component.html',
  styleUrl: './auth-otp.component.scss'
})
export class AuthOtpComponent extends BaseClass implements OnDestroy {

  private email: string | null = null;
  private destroy: Subject<boolean> = new Subject<boolean>();
  private deviceToken!: string;
  protected assetsConst = assets;

  constructor (injector: Injector, private dialogRef: MatDialogRef<AuthOtpComponent>, private _authService: AuthService, private dialog: MatDialog) {
    super(injector);
    this._authService.emailSubject.pipe(takeUntil(this.destroy)).subscribe({next: (res: ForgotPayload | null) => {
      if (res && res !== null) {
        this.email = res.email;
      }
    }});
  }

  fetchOtpSendOutput(event: boolean): void {
    if (event) {
      this.sendOtpAgain();
    }
  }

  sendOtpAgain(): void {
    this.spinnerService.addToLoader('sendOtpAgain');
    const payload: ForgotPayload = {
      email: this.email ? this.email : ''
    };

    this._authService
      .forgotPassword(payload)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (res: CommonResponse) => {
          this.spinnerService.removeFromLoader('sendOtpAgain');
          if (res.statusCode === responseStatus.SUCCESS) {
            this.toasterService.successToastr(res.message);
          } else {
            this.toasterService.errToastr(res.message);
          }
        },
        error: () => {
          this.spinnerService.removeFromLoader('sendOtpAgain');
        }
      });
  }

  fetchOtpSubmitOutput(otp: string): void {
    this.spinnerService.addToLoader('verifyOtp');
    const body: VerifyOtpPayload = {
      otp,
      email: this.email ? this.email : '',
      // deviceType: 'WEB',
      deviceToken: this.deviceToken
    };
    
    this._authService
      .verifyOtp(body)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (res: VerifyOtpResponse) => {
          this.spinnerService.removeFromLoader('verifyOtp');
          if (res) {
            this._authService.resetTokenSubject.next(res.accessToken);
            this.dialogRef.close(AuthOtpComponent);
            this.dialog.open(ResetPasswordComponent);
          }
        },
        error: () => {
          this.spinnerService.removeFromLoader('verifyOtp');
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.complete();
    this.destroy.unsubscribe();
  }

}
