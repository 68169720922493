import { Component, Injector, OnDestroy } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { assets } from '../../../core/const/assetsConst';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs/internal/Subject';
import { emailErrors } from '../../../core/const/commonErrors.const';
import { AuthService } from '../../../services/auth.service';
import { BaseClass } from '../../../shared/class/baseClass';
import { takeUntil } from 'rxjs';
import { CommonResponse } from '../../../models/common.model';
import { responseStatus } from '../../../core/const/responseStatus.const';
import { AuthOtpComponent } from '../auth-otp/auth-otp.component';

@Component({
  selector: 'xplora-luxe-forgot-password',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatIconModule,
    TranslateModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent extends BaseClass implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  otp!: string;
  sendagain = false;
  // timer!: NodeJS.Timeout;
  otpTimer = '';
  adminEmail!: string;
  protected assetsConst = assets;
  protected forgotPasswordFormGroup: FormGroup;
  protected destroy: Subject<boolean> = new Subject<boolean>();
  protected submitted = false;
  protected isShownOtpScreen = false;
  protected emailErrors = emailErrors;

  constructor (
    injector: Injector,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private _authService: AuthService
  ) {
    super(injector);
    this.forgotPasswordFormGroup = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  otpChange(otp: string): void {
    this.otp = otp;
  }

  goBack(): void {
  }

  onSubmit(): void {
    this.submitted = true;
    this.trimFormFields();

    if (this.forgotPasswordFormGroup.valid) this.onFormValid();
  }

  onFormValid(): void {
    this.spinnerService.addToLoader('ForgotEmail');
    this._authService
      .forgotPassword(this.forgotPasswordFormGroup.value)
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (res: CommonResponse) => {
          this.spinnerService.removeFromLoader('ForgotEmail');
          this._authService.emailSubject.next(
            this.forgotPasswordFormGroup.value
          );
          this.toasterService.successToastr(
            this.translateService.instant('otpSentSuccesss')
          );

          if (res.statusCode === responseStatus.SUCCESS) {
            this.dialogRef.close();
            this.dialog.open(AuthOtpComponent, {
              panelClass: ['modal', 'auth_modal']
            });
          }

        },
        error: () => {
          this.spinnerService.removeFromLoader('ForgotEmail');
        }
      });
  }

  trimFormFields(): void {
    this.forgotPasswordFormGroup.patchValue({
      email: this.forgotPasswordFormGroup.get('email')?.value.trim()
    });
  }

  ngOnDestroy(): void {
    this.destroy.complete();
    this.destroy.unsubscribe();
  }

}

