import { Component, Inject, Injector, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { assets } from '../../../core/const/assetsConst';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../../services/auth.service';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { passwordFormErrors } from '../../../core/const/commonErrors.const';
import {
  MustMatch,
  passwordValidatorHelper
} from '../../../core/helpers/validation.helper';
import { REGEX } from '../../../core/const/regex.constant';
import { BaseClass } from '../../../shared/class/baseClass';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'xplora-luxe-reset-password',
  standalone: true,
  imports: [
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule
  ],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent
  extends BaseClass
  implements OnInit, OnDestroy {
  hiddenNewPassowrdVisibility = true;
  hiddenConfirmPasswordVisibility = true;
  submitted = false;
  resetPasswordFormGroup: FormGroup;
  destroy: Subject<boolean> = new Subject<boolean>();
  assetsConst = assets;
  formErrors = passwordFormErrors;
  subscriptionSubject$!: Subscription;

  constructor(
    injector: Injector,
    private dialogRef: MatDialogRef<ResetPasswordComponent>,
    private _authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    super(injector);
    this.resetPasswordFormGroup = this.formBuilder.group(
      {
        newPassword: [
          '',
          [Validators.required, Validators.pattern(REGEX.PASSWORD_PATTERN)]
        ],
        confirmPassword: ['', [Validators.required]]
      },
      { validators: MustMatch('newPassword', 'confirmPassword') }
    );
    this.onControlValueChange();
  }

  onControlValueChange(): void {
    const passwordControl = this.resetPasswordFormGroup.get('newPassword');

    if (passwordControl) {
      passwordControl.setValidators([
        Validators.required,
        this.passwordValidator()
      ]);
    }
  }

  passwordValidator = (): ValidatorFn => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fName = null;
      const userEmail = 'ownrsclub@yopmail.com';

      return passwordValidatorHelper(control, {
        firstName: fName,
        userEmail
      });
    };
  };

  togglePasswordVisibility(type: string): void {
    if (type === 'hiddenConfirmPasswordVisibility') {
      this.hiddenConfirmPasswordVisibility =
        !this.hiddenConfirmPasswordVisibility;
    } else {
      this.hiddenNewPassowrdVisibility = !this.hiddenNewPassowrdVisibility;
    }
  }

  ngOnInit(): void {
    this.subscriptionSubject$ = this._authService.resetTokenSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: (res: string) => {
          if (!res) {
            this.router.navigate(['/auth/login']);
          }
        }
      });
  }

  onSubmit(): void {
    this.submitted = true;
    
    if (this.resetPasswordFormGroup.valid) {
      this.spinnerService.addToLoader('resetPassword');
      this._authService
        .resetPassword(this.resetPasswordFormGroup.value)
        .pipe(takeUntil(this.destroy))
        .subscribe({
          next: () => {
            this.spinnerService.removeFromLoader('resetPassword');
            this.toasterService.successToastr(
              this.translateService.instant('resetPassSuccess')
            );
            if (isPlatformBrowser(this.platformId)) {
              localStorage.clear();
              this.dialogRef.close(ResetPasswordComponent);
              this._authService.openLoginModal();
            }
          },
          error: () => {
            this.spinnerService.removeFromLoader('resetPassword');
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy.complete();
    this.destroy.unsubscribe();
    this.subscriptionSubject$.unsubscribe();
  }
}
