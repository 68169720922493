import { Injector } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../services/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '../../services/spinner.service';

export class BaseClass {

  public route: ActivatedRoute;
  public formBuilder: FormBuilder;
  public spinnerService: SpinnerService;
  public router: Router;
  public toasterService: ToasterService;
  public translateService: TranslateService;

  constructor (injector: Injector) {
    this.route = injector.get(ActivatedRoute);
    this.formBuilder = injector.get(FormBuilder);
    this.spinnerService = injector.get(SpinnerService);
    this.router = injector.get(Router);
    this.toasterService = injector.get(ToasterService);
    this.translateService = injector.get(TranslateService);
  }
}