export const environment = {
  base_url: 'https://xploraluxestagingapi.appgrowthcompany.com/restaurant/api/v1/',
  booking_url: 'https://xploraluxestagingapi.appgrowthcompany.com/operational/api/v1/',
  image_url: 'https://xploraluxestagingapi.appgrowthcompany.com/common/api/v1/upload/uploadDoc',
  rememberMeKey: 'XPR_RM_K_P',
  storageKey: 'XPR_ST_K_P',
  port: '4124',
  googleKey: 'AIzaSyDIZwbQqi5J9a7DAHuTGs8_zbKK6by891s',
  firebaseConfig: {
    apiKey: 'AIzaSyDh0KHjv2UtKo7J0njr5-O5E6hmhUJgXHk',
    authDomain: 'xplora-luxe.firebaseapp.com',
    projectId: 'xplora-luxe',
    storageBucket: 'xplora-luxe.firebasestorage.app',
    messagingSenderId: '768184676302',
    appId: '1:768184676302:web:80f1bbf0f164bcc539059f',
    measurementId: 'G-9ZHR95PGGC',
    vapidKey: 'BPd8igfMKQ7A5D82bBCi1_xFrv3Rf5ZmcCMatUPbO7tWE-Mlhh9CyzgVwnK6E_eWy2lo3f89nzfmDfpG1QdF82s'
  },
  path: '/firebase-messaging-sw.js',
  scope: '/'
};

